<template>
  <div>
    <div @click="hideAllDropdown">
      <BreadCrumb title="Legal Advice" subtitle="Legal Memo/Opinion / Result">
      </BreadCrumb>
      <div class="d-flex justify-content-between align-items-center mt-3">
        <div class="d-flex align-items-center left" @click="goBack">
          <span class="material-symbols-rounded me-2">arrow_back</span>
          <span class="mb-0">Back</span>
        </div>
        <div
          class="d-flex align-items-center right"
          @click="toggleWidth"
          v-if="!isExpanded"
        >
          <span class="material-symbols-rounded me-2">
            {{
              isExpanded
                ? "keyboard_double_arrow_right"
                : "keyboard_double_arrow_left"
            }}
          </span>
          <span class="mb-0">AI Suggestions</span>
        </div>
      </div>
      <PageLoader v-if="pageLoading" />
      <div class="main-container d-flex" v-else>
        <div class="memo-container" :class="{ expanded: isExpanded }">
          <div class="container mt-4" style="max-width: 900px">
            <section class="content-edit-mode">
              <article class="content">
                <header class="header">
                  <div class="title">
                    <h4 class="legal-argument">Legal Memo</h4>
                    <nav class="action-buttons">
                      <button class="button" @click="copyMemo">
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/4042ce02169404fc8f24e2435141f1518abb04892a209162cf916b7ff7de4ac3?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                          alt="Copy icon"
                          class="button-icon"
                        />
                        <span class="button-title">Copy</span>
                      </button>
                      <button id="export-btn" class="button primary">
                        <span class="button-title">Export</span>
                        <span
                          class="material-symbols-rounded"
                          style="font-size: 20px"
                          >keyboard_arrow_down</span
                        >
                      </button>
                      <b-tooltip
                        target="export-btn"
                        triggers="click"
                        placement="bottomright"
                        id="export_box"
                        :custom-class="`DROPDOWN-MENU`"
                      >
                        <div style="padding: 0px 10px">
                          <div
                            v-for="(item, index) in dropdownItems"
                            :key="index"
                            class="dropdown-option d-flex align-items-center"
                          >
                            <CheckBox
                              :set="selectedExportOptions.includes(item)"
                              @input="toggleExport($event, item)"
                            ></CheckBox>
                            <label
                              :for="`option-${index}`"
                              style="margin-left: 8px"
                              >{{ item }}</label
                            >
                          </div>
                          <div
                            class="d-flex align-items-center justify-content-end"
                          >
                            <button @click="exportMemo" class="btn btn-primary">
                              <span
                                class="material-symbols-rounded"
                                style="font-size: 20px"
                                >ios_share</span
                              >
                              <span>Export</span>
                            </button>
                          </div>
                        </div>
                      </b-tooltip>
                    </nav>
                  </div>
                </header>
                <section class="section-content">
                  <!-- Statement Section -->
                  <div class="info-block" v-if="subject">
                    <header class="d-flex justify-space-between">
                      <h5 class="info-title">Subject:</h5>
                      <span
                        class="material-symbols-rounded icon"
                        @click="toggleEdit('subject')"
                        >border_color</span
                      >
                    </header>
                    <div class="info-content" v-if="!isEditing.subject">
                      <article v-html="subject"></article>
                    </div>
                    <div v-if="isEditing.subject">
                      <quill-editor
                        class="mt-2 quillEditor"
                        v-model="subject"
                        :options="optionsContent"
                      ></quill-editor>
                      <div class="d-flex justify-content-end save_cancel">
                        <p class="cancel" @click="cancelEdit('subject')">
                          Cancel
                        </p>
                        <p
                          class="save"
                          @click="
                            saveChange(
                              contentDetail['subject'],
                              null,
                              'subject',
                              subject
                            )
                          "
                        >
                          Save
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- Introduction Section -->
                  <div class="info-block" v-if="question_presented">
                    <header class="d-flex justify-space-between">
                      <h5 class="info-title">Questions Presented:</h5>
                      <span
                        class="material-symbols-rounded icon"
                        @click="toggleEdit('question_presented')"
                        >border_color</span
                      >
                    </header>
                    <div
                      class="info-content"
                      v-if="!isEditing.question_presented"
                    >
                      <article v-html="question_presented"></article>
                    </div>

                    <div v-if="isEditing.question_presented">
                      <quill-editor
                        class="mt-2 quillEditor"
                        v-model="question_presented"
                        :options="optionsContent"
                      ></quill-editor>
                      <div class="d-flex justify-content-end save_cancel">
                        <p
                          class="cancel"
                          @click="cancelEdit('question_presented')"
                        >
                          Cancel
                        </p>
                        <p
                          class="save"
                          @click="
                            saveChange(
                              contentDetail['question_presented'],
                              null,
                              'question_presented',
                              question_presented
                            )
                          "
                        >
                          Save
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="info-block" v-if="brief_answer">
                    <header class="d-flex justify-space-between">
                      <h5 class="info-title">Brief Answer:</h5>
                      <span
                        class="material-symbols-rounded icon"
                        @click="toggleEdit('brief_answer')"
                        >border_color</span
                      >
                    </header>
                    <div class="info-content" v-if="!isEditing.brief_answer">
                      <article v-html="brief_answer"></article>
                    </div>
                    <div v-if="isEditing.brief_answer">
                      <quill-editor
                        class="mt-2 quillEditor"
                        v-model="brief_answer"
                        :options="optionsContent"
                      ></quill-editor>
                      <div class="d-flex justify-content-end save_cancel">
                        <p class="cancel" @click="cancelEdit('brief_answer')">
                          Cancel
                        </p>
                        <p
                          class="save"
                          @click="
                            saveChange(
                              contentDetail['brief_answer'],
                              null,
                              'brief_answer',
                              brief_answer
                            )
                          "
                        >
                          Save
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- Discussion Section -->
                  <div class="info-block" v-if="discussionContent">
                    <header class="d-flex justify-space-between">
                      <h5 class="info-title">Discussion:</h5>
                      <span
                        class="material-symbols-rounded icon"
                        @click="toggleEdit('discussionContent')"
                        >border_color</span
                      >
                    </header>
                    <div
                      class="info-content"
                      v-if="!isEditing.discussionContent"
                    >
                      <article v-html="discussionContent"></article>
                    </div>
                    <div v-if="isEditing.discussionContent">
                      <quill-editor
                        class="mt-2 quillEditor"
                        v-model="discussionContent"
                        :options="optionsContent"
                      ></quill-editor>
                      <div class="d-flex justify-content-end save_cancel">
                        <p
                          class="cancel"
                          @click="cancelEdit('discussionContent')"
                        >
                          Cancel
                        </p>
                        <p
                          class="save"
                          @click="
                            saveChange(
                              discussionDetail.id,
                              discussionDetail.theme,
                              null,
                              discussionContent
                            )
                          "
                        >
                          Save
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- Conclusion Section -->
                  <div class="info-block" v-if="conclusion || recommendations">
                    <header class="d-flex justify-space-between">
                      <h5 class="info-title">Conclusion & Recommendations:</h5>
                      <span
                        class="material-symbols-rounded icon"
                        @click="toggleEdit('conclusion')"
                        >border_color</span
                      >
                    </header>
                    <div class="info-content" v-if="!isEditing.conclusion">
                      <article v-html="conclusion"></article>
                    </div>
                    <div v-if="isEditing.conclusion">
                      <quill-editor
                        class="mt-2 quillEditor"
                        v-model="conclusion"
                        :options="optionsContent"
                      ></quill-editor>
                      <div class="d-flex justify-content-end save_cancel">
                        <p class="cancel" @click="cancelEdit('conclusion')">
                          Cancel
                        </p>
                        <p
                          class="save"
                          @click="
                            saveChange(
                              contentDetail['conclusion'],
                              null,
                              'conclusion',
                              conclusion
                            )
                          "
                        >
                          Save
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- Doctrines Section -->
                  <div class="info-block citations" v-if="overturn.length">
                    <header class="">
                      <h5 class="info-title m-0">Doctrines:</h5>
                    </header>
                    <div
                      v-for="(doctrine, index) in overturn"
                      :key="index"
                      class="tabs"
                    >
                      <h5 class="doctrine-title mt-3">
                        {{ doctrine.doct_title }}
                      </h5>
                      <p class="info-content mb-1">
                        Status:
                        <span
                          class="ms-2"
                          style="color: var(--Status-Green, #52c41a) !important"
                          >{{ doctrine.status }}</span
                        >
                      </p>
                      <p class="info-content" style="margin: 0px !important">
                        Finalized Cases:
                      </p>
                      <button class="p-0 mt-1">
                        <a
                          :href="doctrine.citation_url"
                          target="_blank"
                          style="color: var(--Status-Blue, #1890ff)"
                          >{{ doctrine.finalized_case }}</a
                        >
                      </button>
                      <p class="info-content mt-1">
                        Summary: {{ doctrine.case_summary }}
                      </p>
                    </div>
                  </div>

                  <!-- Citations Section -->
                  <div class="info-block citations">
                    <header class="">
                      <h5 class="info-title">Citations:</h5>
                    </header>
                    <ol class="citation-list">
                      <li
                        class="citation-item"
                        v-for="(cite, index) in citation"
                        :key="index"
                      >
                        <a :href="cite.url" target="_blank">{{ cite.name }}</a>
                      </li>
                    </ol>
                  </div>
                </section>
              </article>
            </section>
          </div>
        </div>

        <div v-if="isExpanded" class="sidebar expanded-sidebar">
          <lawbot
            @closeSidebar="toggleWidth"
            :id="Number(id)"
            :discussionId="Number(discussion[0].id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import lawbot from "./lawbot.vue";
import BreadCrumb from "../../components/misc/BreadCrumb.vue";
import advice from "../../store/advice";
import PageLoader from "../../components/input/Loader.vue";
import CheckBox from "../../components/input/CheckBox.vue";
import { ExportData } from "../../store/utils";
import marked from "marked";

export default {
  components: {
    lawbot,
    BreadCrumb,
    PageLoader,
    CheckBox,
  },
  data() {
    return {
      pageLoading: true,
      isExpanded: false,
      subject: "",
      question_presented: "",
      brief_answer: "",
      discussion: "",
      recommendations: "",
      discussionContent: "",
      discussionDetail: {},
      contentDetail: {},
      conclusion: "",
      citation: [],
      overturn: [],
      isEditing: {
        subject: false,
        question_presented: false,
        discussionContent: false,
        brief_answer: false,
        discussion: false,
        conclusion: false,
        citation: false,
        recommendations: false,
      },
      optionsContent: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            [{ color: [] }, { background: [] }],
            [{ align: [] }, { list: "ordered" }, { list: "bullet" }],
          ],
        },
      },
      dropdownItems: ["PDF", "Document"],
      selectedExportOptions: [],
    };
  },
  methods: {
    hideAllDropdown() {
      this.$root.$emit("bv::hide::tooltip");
    },
    goBack() {
      this.$router.push({ name: "LegalAdvice" });
    },
    copyMemo() {
      const textToCopy = `Subject:\n
${this.subject || ""}

Questions Presented:\n
${this.question_presented || ""}

Brief Answer:\n
${this.brief_answer || ""}

Discussion:\n
${this.discussionContent || ""}

Conclusion & Recommendations::\n
${this.conclusion || ""}

Citations:\n
${this.citation.map((cite) => `${cite.name}: ${cite.url}`).join("\n") || ""}

Doctrines:
${
  this.overturn
    .map(
      (doctrine, index) =>
        `\n${index + 1}. ${doctrine.doct_title}\nStatus: ${
          doctrine.status
        }\nFinalized Cases:\n${doctrine.finalized_case}: ${
          doctrine.citation_url
        }\nSummary: ${doctrine.case_summary}`
    )
    .join("\n") || ""
}`;

      // Copy the text to clipboard
      navigator.clipboard
        .writeText(textToCopy.replace(/<[^>]*>/g, ""))
        .then(() => {
          this.$toast.success("Copied to clipboard!");
        })
        .catch((err) => {
          console.error("Error copying text: ", err);
          this.$toast.error("Failed to copy!");
        });
    },
    toggleExport(e, item) {
      if (e) {
        if (!this.selectedExportOptions.includes(item))
          this.selectedExportOptions.push(item);
      } else if (!e) {
        const index = this.selectedExportOptions.indexOf(item);
        this.selectedExportOptions.splice(index, 1);
      }
    },
    exportMemo() {
      if (!this.selectedExportOptions.length) {
        this.$toast.error("Kindly select a format to export");
        return;
      }

      const textToExport = `Subject:\n
${this.subject || ""}

Questions Presented:\n
${this.question_presented || ""}

Brief Answer:\n
${this.brief_answer || ""}

Discussion:\n
${this.discussionContent || ""}

Conclusion & Recommendations::\n
${this.conclusion || ""}

Citations:\n
${this.citation.map((cite) => `${cite.name}: ${cite.url}`).join("\n") || ""}

Doctrines:
${
  this.overturn
    .map(
      (doctrine, index) =>
        `\n${index + 1}. ${doctrine.doct_title}\nStatus: ${
          doctrine.status
        }\nFinalized Cases:\n${doctrine.finalized_case}: ${
          doctrine.citation_url
        }\nSummary: ${doctrine.case_summary}`
    )
    .join("\n") || ""
}`;

      let status = ExportData(textToExport.replaceAll("\n", "<br>"), [
        this.selectedExportOptions.find((item) => item === "Document"),
        this.selectedExportOptions.find((item) => item === "PDF"),
      ]);

      if (status[0]) this.$toast.success("Successfully downloaded Document");
      if (status[1]) this.$toast.success("Successfully downloaded PDF");

      this.selectedExportOptions = [];
    },
    toggleWidth() {
      this.isExpanded = !this.isExpanded;
    },
    toggleEdit(section) {
      // Reset all edit states before enabling the clicked section's editor
      for (let key in this.isEditing) {
        this.isEditing[key] = false;
      }
      this.isEditing[section] = true;
    },
    cancelEdit(section) {
      this.isEditing[section] = false;
    },
    saveChange(id, type, key, content) {
      const lowercaseType = type ? type.toLowerCase() : null;

      var obj = {
        entity_id: id,
        entity_type:
          lowercaseType == "statement" || lowercaseType == "discussion"
            ? lowercaseType
            : "value",
        content: content,
      };

      if (obj.entity_type === "value") {
        obj.key = key;
      }

      advice
        .SaveChanges(this.$route.params.id, obj, "LArg")
        .then(() => {
          this.$toast.success("Saved");
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          if (lowercaseType === "discussion") {
            this.isEditing.discussionContent = false;
          }
          lowercaseType == "statement"
            ? (this.isEditing[lowercaseType] = false)
            : (this.isEditing[key] = false);
        });
    },
  },
  created() {
    advice
      .ViewLegalWriter(this.$route.params.id)
      .then((response) => {
        const DATA = response.data.data;
        this.pageLoading = false;
        this.statement = DATA.statement;
        this.discussionContent = DATA.discussions.length
          ? marked.parse(DATA.discussions[0].content)
          : "No discussions available";
        this.discussionDetail = {
          id: DATA.discussions[0]?.id,
          theme: DATA.discussions[0]?.theme,
        };
        const values = DATA.values;
        values.forEach((item) => {
          if (item.key === "citations") {
            this.citation = JSON.parse(item.value).map((cite) => {
              const [name, url] = Object.entries(cite)[0];
              return { name, url: url[0] };
            });
          } else if (item.key === "conclusion") {
            this.conclusion = marked.parse(item.value);
          } else if (item.key === "subject") {
            this.subject = marked.parse(item.value);
          } else if (item.key === "question_presented") {
            this.question_presented = marked.parse(item.value);
          } else if (item.key === "brief_answer") {
            this.brief_answer = marked.parse(item.value);
          } else if (item.key === "recommendations") {
            this.recommendations = marked.parse(item.value);
          } else if (item.key === "overturn") {
            this.overturn = JSON.parse(item.value);
          }

          this.contentDetail[item.key] = item.id;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped>
.left {
  color: var(--primary);
  font-weight: 500;
  cursor: pointer;
}

.right {
  background: var(--primary);
  font-weight: 500;
  color: #fff;
  height: 32px;
  padding: 4px 16px;
  gap: 8px;
  border-radius: 6px;
  cursor: pointer;
}
</style>
<style scoped>
.legal-memo {
  max-width: 880px;
  margin: 0 auto;
  padding: 16px 32px 32px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
}

.memo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 24px;
}

.memo-title {
  font-size: 20px;
  font-weight: 600;
  color: #383a3e;
  margin: 0;
}

.action-buttons {
  display: flex;
  gap: 8px;
}

.btn {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.btn-secondary {
  border: 1px solid #d1d2d5;
  background-color: #fff;
  color: #000046;
}

.btn-primary {
  background-color: #0e4485;
  color: #fff;
  border: none;
}

.btn-icon {
  width: 18px;
  height: 18px;
}

.memo-section {
  margin-bottom: 24px;
}

.section-title {
  font-size: 16px;
  font-weight: 500;
  color: #383a3e;
  margin-bottom: 4px;
}

.section-content {
  font-size: 14px;
  color: #86888d;
  line-height: 1.5;
}

.highlighted-section {
  background-color: #f2f3f3;
  border-radius: 16px;
  padding: 8px 16px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expand-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.expand-icon {
  width: 22px;
  height: 22px;
}

.section-divider {
  border: none;
  border-top: 1px solid #d1d2d5;
  margin: 24px 0;
}

.citation-list {
  padding-left: 20px;
  font-size: 14px;
  color: #1890ff;
}

.citation-list a {
  color: inherit;
  text-decoration: underline;
}

@media (max-width: 991px) {
  .legal-memo {
    padding: 16px 20px;
  }

  .memo-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .action-buttons {
    width: 100%;
    justify-content: flex-end;
  }
}

.main-container {
  display: flex;
  justify-content: space-between;
}

.memo-container {
  width: 100%;
  /* Default width */
  transition: width 0.3s ease;
}

.sidebar {
  transition: width 0.3s ease;
}

.memo-container.expanded {
  width: 65%;
  /* New width when expanded */
}

.sidebar.expanded-sidebar {
  display: block;
  width: 28%;
  position: fixed;
  right: 0;
  top: 65px;
}
</style>
<style scoped>
.content-edit-mode {
  justify-content: center;
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0px 4px 10px 0px rgba(227, 229, 235, 0.6);
  background-color: var(--Neutral-White, #fff);
  display: flex;
  max-width: 848px;
  flex-direction: column;
  padding: 16px;
}

.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 991px) {
  .content {
    max-width: 100%;
  }
}

.header {
  display: flex;
  width: 100%;
  height: 24px;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px;
  border: none;
}

.icon {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
}

.info-block:hover .icon {
  visibility: visible;
  opacity: 1;
  color: #000;
  cursor: pointer;
}

@media (max-width: 991px) {
  .header {
    max-width: 100%;
  }
}

.title {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 40px 100px;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .title {
    max-width: 100%;
  }
}

.legal-argument {
  color: var(--Neutral-Black, #383a3e);
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  align-self: stretch;
  margin: auto 0;
}

.action-buttons {
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  line-height: 1;
  justify-content: flex-end;
  margin: auto 0;
}

@media (max-width: 991px) {
  .action-buttons {
    white-space: initial;
  }
}

.button {
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--Neutral-White, #fff);
  background-color: var(--Neutral-White, #fff);
  align-self: stretch;
  display: flex;
  gap: 4px;
  overflow: hidden;
  color: var(--Primary-Dark-Blue, #000046);
  margin: auto 0;
  padding: 4px 8px;
}

@media (max-width: 991px) {
  .button {
    white-space: initial;
  }
}

.button.primary {
  background: var(--Primary-Blue, #0e4485);
  background-color: var(--Primary-Blue, #0e4485);
  color: var(--Neutral-White, #fff);
}

.button-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 18px;
  align-self: stretch;
  margin: auto 0;
}

.button-title {
  align-self: stretch;
  margin: auto 0;
}

.section-content {
  position: relative;
  display: flex;
  margin-top: 24px;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

@media (max-width: 991px) {
  .section-content {
    max-width: 100%;
  }
}

.info-block {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 16px 16px;
  border-bottom: 1px solid var(--mid-grey);
  transition: background-color 0.3s;
}

.info-block:last-child {
  border-bottom: 1px solid transparent;
}

.info-block:hover {
  background-color: var(--light-grey);
  border-radius: 16px;
}

@media (max-width: 991px) {
  .info-block {
    max-width: 100%;
  }
}

.info-title {
  flex: 1;
  width: 100%;
  gap: 10px;
  font-size: 16px;
  color: var(--Neutral-Black, #383a3e);
  font-weight: 500 !important;
  white-space: nowrap;
  line-height: 28px;
}

.doctrine-title {
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-weight: 500 !important;
}

@media (max-width: 991px) {
  .info-title {
    max-width: 100%;
    white-space: initial;
  }
}

.info-content {
  display: flex;
  margin-top: 4px;
  width: 100%;
  align-items: center;
  font-size: 14px;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-weight: 400;
  line-height: 24px;
  justify-content: flex-start;
}

@media (max-width: 991px) {
  .info-content {
    max-width: 100%;
  }
}

.divider {
  object-fit: contain;
  object-position: center;
  width: 100%;
  stroke-width: 1px;
  stroke: var(--Neutral-Mid-Grey, #d1d2d5);
  z-index: 0;
  margin-top: 24px;
}

@media (max-width: 991px) {
  .divider {
    max-width: 100%;
  }
}

.edit-block {
  display: flex;
  margin-top: 24px;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

@media (max-width: 991px) {
  .edit-block {
    max-width: 100%;
  }
}

.edit-title {
  flex: 1;
  width: 100%;
  gap: 10px;
  color: var(--Neutral-Black, #383a3e);
  font: 500 16px/28px Poppins, sans-serif;
}

@media (max-width: 991px) {
  .edit-title {
    max-width: 100%;
    white-space: initial;
  }
}

.edit-form {
  border-radius: 16px;
  background: var(--Neutral-Interface-Grey, #fafbfc);
  background-color: var(--Neutral-Interface-Grey, #fafbfc);
  position: relative;
  display: flex;
  margin-top: 8px;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  padding: 56px 16px 8px;
}

@media (max-width: 991px) {
  .edit-form {
    max-width: 100%;
  }
}

.edit-textarea {
  width: 100%;
  height: 8rem;
  font: 400 14px/24px Poppins, sans-serif;
  color: var(--Neutral-Dark-Grey, #86888d);
  padding: 8px;
  box-sizing: border-box;
}

.form-actions {
  display: flex;
  margin-top: 8px;
  width: 100%;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  justify-content: flex-end;
  flex-wrap: wrap;
  font: 500 14px/1 Poppins, sans-serif;
}

@media (max-width: 991px) {
  .form-actions {
    max-width: 100%;
    white-space: initial;
  }
}

.menu-bar {
  align-items: flex-start;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid var(--Neutral-Mid-Grey, #d1d2d5);
  background: var(--Neutral-Light-Grey, #f2f3f3);
  background-color: var(--Neutral-Light-Grey, #f2f3f3);
  position: absolute;
  display: flex;
  width: 816px;
  gap: 8px;
  justify-content: flex-start;
  flex-wrap: wrap;
  right: 0px;
  top: 0px;
  padding: 8px;
}

@media (max-width: 991px) {
  .menu-bar {
    max-width: 100%;
  }
}

.button-group {
  display: flex;
  gap: 2px;
  justify-content: flex-start;
}

.menu-button {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 28px;
}

.menu-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  align-self: stretch;
  margin: auto 0;
}

.dropdown {
  display: flex;
  gap: 2px;
  color: var(--Neutral-Black, #383a3e);
  font: 400 14px/1.4 Noto Sans, sans-serif;
  justify-content: flex-start;
}

.dropdown-button {
  border-radius: 4px;
  display: flex;
  min-height: 28px;
  align-items: center;
  gap: 2px;
  justify-content: center;
  padding: 0 4px 0 8px;
}

.dropdown-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  align-self: stretch;
  margin: auto 0;
}

.color-picker {
  display: flex;
}

.color-swatch {
  border-radius: 4px;
  background: var(--Neutral-Black, #383a3e);
  display: flex;
  width: 20px;
  height: 20px;
}

.color-border {
  border-radius: 4px;
  border: 1px solid var(--Neutral-Black, #383a3e);
  display: flex;
  width: 20px;
  height: 20px;
}

.format-buttons {
  display: flex;
  gap: 2px;
  justify-content: flex-start;
}

.list-buttons {
  display: flex;
  gap: 2px;
  justify-content: flex-start;
}

.citations {
  display: flex;
  margin-top: 24px;
  justify-content: center;
  padding: 8px 16px;
}

.citations:hover {
  background-color: transparent;
  border-radius: 0px;
}

@media (max-width: 991px) {
  .citations {
    max-width: 100%;
  }
}

.citation-list {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 4px;
}

.citation-item {
  font-size: 14px;
  color: var(--Status-Blue, #1890ff);
  font-weight: 400;
  line-height: 24px;
}

.citation-item a {
  color: var(--Status-Blue, #1890ff);
  text-decoration: underline;
}

.save_cancel {
  background: #fafbfc;
}

.cancel {
  border: 1px solid #d1d2d5;
  width: 67px;
  padding: 2px 8px;
  gap: 4px;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}

.save {
  background: #0e4485;
  color: #fff !important;
  width: 51px;
  padding: 2px 8px;
  gap: 4px;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}

.quillEditor .ql-toolbar.ql-snow {
  border-radius: 8px 0px !important;
  border-bottom: 1px solid #d1d2d5 !important;
  background: #f2f3f3 !important;
}

.quillEditor .ql-container.ql-snow {
  background: #fafbfc !important;
}

#Header {
  background: #ffffff !important;
}

.DROPDOWN-MENU::v-deep .tooltip-inner {
  max-width: 230px;
  width: 230px;
}

.DROPDOWN-MENU::v-deep .tooltip-inner .dropdown-option span {
  margin-right: 0px;
}
</style>
